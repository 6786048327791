.dialog-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.33);
  z-index: 99999;

  .dialog-content {
    margin: 0 20px;
    border: 1px solid #ebeef5;
    border-radius: $global-radius;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: auto;
    max-height: 80%;
  }
}
