.button {
  @include button-style(get-color(secondary), auto, auto);

  margin-bottom: 0;
  text-decoration: none;

  + .button {
    margin-left: 0.5em * 2;
  }

  &.-primary {
    @include button-style(get-color(primary), auto);
  }

  &.-small {
    font-size: 90%;
    padding: 0.4em 0.5em;

    + .button.-small {
      margin-left: 0.5em;
    }
  }

  &.small {
    + .button.small {
      margin-left: 0.5em;
    }
  }

  &.-danger:hover {
    background-color: color("alert");
  }
}

[type="checkbox"],
[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  display: block;
  height: 0;
  margin: 0;
  opacity: 0;
  width: 0;

  & + label {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    font-weight: normal;
    justify-content: flex-start;
    line-height: 1.4;
    margin-left: 0;
    margin-right: 0;
    padding-top: 1px;

    ._input-glyph {
      align-items: center;
      background: $input-background;
      border: $input-border;
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      height: 18px;
      justify-content: center;
      margin: 0 5px 2px 2px;
      width: 18px;
    }

    ._input-glyph::before {
      display: none;
    }
  }

  &:checked {
    & + label {
      ._input-glyph::before {
        display: block;
      }
    }
  }

  &:active,
  &:focus {
    & + label ._input-glyph {
      background: $input-background-focus;
      border: $input-border-focus;
    }
  }

  &:disabled {
    & + label {
      opacity: 0.6;

      ._input-glyph {
        background: #e9e9e9;
      }
    }
  }
}

[type="radio"] {
  & + label ._input-glyph {
    border-radius: 50%;

    &::before {
      background: color("text");
      border-radius: 50%;
      content: "";
      height: 10px;
      width: 10px;
    }
  }
}

[type="checkbox"] {
  & + label ._input-glyph::before {
    color: color("text");
    content: "\f126";
    //height: 12px;
    font-size: 17px;
    margin: 3px;
    //width: 12px;
    font-family: "foundation-icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    text-decoration: inherit;
  }
}
