$multiselect-color-brand-light: color("secondary");
$multiselect-color-brand-dark: color("page");

$multiselect-color-group: #ff6a6a;

$multiselect-color-text: $input-color;
$multiselect-color-text-active: color("page");
$multiselect-color-group-text-disabled: #a6a6a6;

$multiselect-color-background: #fff;
$multiselect-color-background-active: $multiselect-color-brand-light;
$multiselect-color-background-disabled: #ededed;
$multiselect-color-background-group-highlight: $multiselect-color-text;
$multiselect-color-background-group-disabled: #dedede;
$multiselect-color-background-group-selected: #f3f3f3;

$multiselect-color-border-focus: #a8a8a8;
$multiselect-color-border-hover: #cfcfcf;

$multiselect-color-item-border: color("input", "border");

.multiselect {
  $height: ($input-font-size * unitless-calc($input-line-height)) +
    (get-side($input-padding, "top") + get-side($input-padding, "bottom")) -
    rem-calc(1);

  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  //height: $height;
  min-height: $height;
  text-align: left;
  color: $multiselect-color-text;
  font-family: inherit;
  font-size: $input-font-size;
  touch-action: manipulation;

  * {
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
  }

  .multiselect__spinner {
    position: absolute;
    right: $global-radius / 2 + 1;
    top: 1px;
    width: 48px;
    height: $height - rem-calc(1);
    background: $multiselect-color-background;
    display: block;

    &:before,
    &:after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -8px;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      border-color: $multiselect-color-brand-light;
      border-style: solid;
      border-width: 2px;
      box-shadow: 0 0 0 1px transparent;
    }

    &:before {
      animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
      animation-iteration-count: infinite;
    }

    &:after {
      animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
      animation-iteration-count: infinite;
    }
  }

  .multiselect__loading-enter-active,
  .multiselect__loading-leave-active {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
  }

  .multiselect__loading-enter,
  .multiselect__loading-leave-active {
    opacity: 0;
  }

  .multiselect__input,
  .multiselect__single {
    position: relative;
    display: inline-block;
    //min-height: 20px;
    //line-height: 20px;
    border: none;
    border-radius: $global-radius;
    background: $multiselect-color-background;
    padding: 0;
    width: calc(100%);
    transition: border 0.1s ease;
    box-sizing: border-box;
    margin-bottom: get-side($input-padding, "bottom") - rem-calc(1);
    vertical-align: top;
    font-family: inherit;
    font-size: $input-font-size;
    touch-action: manipulation;
  }

  .multiselect__current {
    //line-height: 16px;
    min-height: $height;
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    padding: get-side($input-padding, "top") - rem-calc(1) 12px 0;
    padding-right: 30px;
    white-space: nowrap;
    margin: 0;
    text-decoration: none;
    border-radius: $global-radius;
    border: 1px solid $multiselect-color-item-border;
    cursor: pointer;
  }

  .multiselect__select {
    //line-height: 16px;
    display: block;
    position: absolute;
    box-sizing: border-box;
    width: $height;
    height: calc(#{$height} - 2px);
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    margin: 0;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease;

    &:before {
      position: relative;
      right: 0;
      top: 65%;
      color: #999;
      margin-top: 4px;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #999999 transparent transparent transparent;
      content: "";
    }
  }

  .multiselect--disabled {
    pointer-events: none;
    opacity: 0.6;
    background: $multiselect-color-background-disabled;

    .multiselect__current,
    .multiselect__select {
      background: $multiselect-color-background-disabled;
      color: $multiselect-color-group-text-disabled;
    }
  }

  .multiselect__single {
    &:focus {
      border-color: $multiselect-color-border-focus;
      outline: none;
    }

    //padding-left: 5px;
    margin-bottom: get-side($input-padding, "bottom") - rem-calc(1);
  }

  .multiselect__tag ~ {
    .multiselect__input,
    .multiselect__single {
      width: auto;
    }
  }

  .multiselect__input:hover,
  .multiselect__single:hover {
    border-color: $multiselect-color-border-hover;
  }

  .multiselect__input:focus {
    border-color: $multiselect-color-border-focus;
    outline: none;
  }

  .multiselect__tags {
    min-height: $height;
    display: block;
    padding: get-side($input-padding, "top") - rem-calc(1) 40px 0
      get-side($input-padding, "left");
    border-radius: $global-radius;
    border: 1px solid $multiselect-color-item-border;
    background: $multiselect-color-background;
    font-size: $input-font-size;
  }

  .multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top;
  }

  .multiselect__content::-webkit-scrollbar {
    display: none;
  }

  .multiselect__option {
    display: block;
    padding: $input-padding;
    min-height: $height;
    line-height: $input-line-height;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: nowrap;

    &:after {
      top: 0;
      right: 0;
      position: absolute;
      line-height: $height;
      padding-right: 12px;
      padding-left: 20px;
      font-size: 13px;
    }

    &:hover {
      background-color: color("primary");
      color: color("page");
    }
  }

  .multiselect--active {
    z-index: 50;

    &:not(.multiselect--above) {
      .multiselect__current,
      .multiselect__input,
      .multiselect__tags {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .multiselect__select {
      transform: rotateZ(180deg);
    }
  }

  .multiselect--above.multiselect--active {
    .multiselect__current,
    .multiselect__input,
    .multiselect__tags {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .multiselect__input::placeholder {
    color: $multiselect-color-text;
  }

  .multiselect__tags-wrap {
    display: inline;
  }

  .multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: $global-radius;
    margin-right: 10px;
    color: $multiselect-color-text-active;
    line-height: 1;
    background: $multiselect-color-background-active;
    //margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  .multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: initial;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all 0.2s ease;
    border-radius: $global-radius;

    &:after {
      content: "×";
      color: $multiselect-color-brand-dark;
      font-size: 14px;
    }

    &:focus,
    &:hover {
      background: $multiselect-color-background-active;
    }

    &:focus:after,
    &:hover:after {
      color: $multiselect-color-text-active;
    }
  }

  .multiselect__placeholder {
    color: color("input", "placeholder");
    display: inline-block;
    margin-bottom: get-side($input-padding, "bottom") - rem-calc(1);
    //padding-top: 2px;
  }

  .multiselect--active .multiselect__placeholder {
    display: none;
  }

  .multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: $multiselect-color-background;
    width: 100%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: $global-radius;
    border-bottom-right-radius: $global-radius;
    z-index: 50;
    -webkit-overflow-scrolling: touch;
  }

  .multiselect--above .multiselect__content-wrapper {
    bottom: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: $global-radius;
    border-top-right-radius: $global-radius;
    border-bottom: none;
    border-top: 1px solid $multiselect-color-item-border;
  }

  .multiselect__element {
    display: block;
  }

  .multiselect__option--highlight {
    background: $multiselect-color-background-active;
    outline: none;
    color: $multiselect-color-text-active;

    &:after {
      content: attr(data-select);
      background: $multiselect-color-background-active;
      color: $multiselect-color-text-active;
    }
  }

  .multiselect__option--selected {
    background: $multiselect-color-background-group-selected;
    color: $multiselect-color-text;
    font-weight: bold;

    &:after {
      content: attr(data-selected);
      color: silver;
    }

    &.multiselect__option--highlight {
      background: $multiselect-color-group;
      color: $multiselect-color-text-active;

      &:after {
        background: $multiselect-color-group;
        content: attr(data-deselect);
        color: $multiselect-color-text-active;
      }
    }
  }

  .multiselect__option--disabled {
    background: $multiselect-color-background-disabled !important;
    color: $multiselect-color-group-text-disabled !important;
    cursor: text;
    pointer-events: none;
  }

  .multiselect__option--group {
    background: $multiselect-color-background-disabled;
    color: $multiselect-color-text;

    &.multiselect__option--highlight {
      background: $multiselect-color-background-group-highlight;
      color: $multiselect-color-text-active;

      &:after {
        background: $multiselect-color-background-group-highlight;
      }
    }
  }

  .multiselect__option--disabled.multiselect__option--highlight {
    background: $multiselect-color-background-group-disabled;
  }

  .multiselect__option--group-selected.multiselect__option--highlight {
    background: $multiselect-color-group;
    color: $multiselect-color-text-active;

    &:after {
      background: $multiselect-color-group;
      content: attr(data-deselect);
      color: $multiselect-color-text-active;
    }
  }

  .multiselect-enter-active,
  .multiselect-leave-active {
    transition: all 0.15s ease;
  }

  .multiselect-enter,
  .multiselect-leave-active {
    opacity: 0;
  }

  .multiselect__strong {
    margin-bottom: get-side($input-padding, "bottom") - rem-calc(1);
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
  }

  @keyframes spinning {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(2turn);
    }
  }
}

fieldset[disabled] .multiselect {
  pointer-events: none;
}
