.VuePagination {
  > nav {
    width: 100%;
  }

  .VuePagination__count {
    margin-bottom: 0;
  }

  .pagination-control {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    margin-bottom: 0.5em;
    margin-left: 0;

    ._page-item {
      display: block;
      margin: 0 $pagination-item-spacing;

      ._pagination-link {
        color: inherit;
        display: block;
        font-weight: bold;
        text-decoration: none;
        padding: $pagination-item-padding;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover ._pagination-link {
        //text-decoration: underline;
        background-color: $pagination-item-background-hover;
      }

      &.-active ._pagination-link {
        background-color: $pagination-item-background-current;
        color: $pagination-item-color-current;
      }

      &.-disabled {
        &._prev-item,
        &._next-item {
          visibility: hidden;
        }

        ._pagination-link {
          cursor: default;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
