@import "~foundation-sites/scss/util/_util.scss";

$black: #0a0a0a;
$white: #fefefe;

$foundation-palette: (
  primary: color("primary"),
  secondary: color("secondary"),
  success: color("success"),
  warning: color("warning"),
  alert: color("alert"),
);

$global-font-size: 13px;
$body-font-color: color("text");
$body-font-family: $font-text;

$font-size-coef: 0.82;
$font-size-coef-medium: 0.53;
$font-size-coef-large: 0.63;

$header-styles: (
  small: (
    "h1": (
      "font-size": 24 * $font-size-coef,
    ),
    "h2": (
      "font-size": 20 * $font-size-coef,
    ),
    "h3": (
      "font-size": 19 * $font-size-coef,
    ),
    "h4": (
      "font-size": 18 * $font-size-coef,
    ),
    "h5": (
      "font-size": 17 * $font-size-coef,
    ),
    "h6": (
      "font-size": 16 * $font-size-coef,
    ),
  ),
  medium: (
    "h1": (
      "font-size": 48 * $font-size-coef-medium,
    ),
    "h2": (
      "font-size": 40 * $font-size-coef-medium,
    ),
    "h3": (
      "font-size": 31 * $font-size-coef-medium,
    ),
    "h4": (
      "font-size": 25 * $font-size-coef-medium,
    ),
    "h5": (
      "font-size": 20 * $font-size-coef-medium,
    ),
    "h6": (
      "font-size": 16 * $font-size-coef-medium,
    ),
  ),
  large: (
    "h1": (
      "font-size": 48 * $font-size-coef-large,
    ),
    "h2": (
      "font-size": 40 * $font-size-coef-large,
    ),
    "h3": (
      "font-size": 31 * $font-size-coef-large,
    ),
    "h4": (
      "font-size": 25 * $font-size-coef-large,
    ),
    "h5": (
      "font-size": 20 * $font-size-coef-large,
    ),
    "h6": (
      "font-size": 16 * $font-size-coef-large,
    ),
  ),
);

$global-radius: 4px;
$global-lineheight: 1.5;

$form-spacing: rem-calc(16);
$input-color: $black;
$input-font-size: 1rem;
$input-line-height: $global-lineheight;
$input-padding: $form-spacing / 2;
$form-label-font-size: 1rem;

$input-shadow: none;
$input-shadow-focus: none;
$input-transition: background 0.45s, border-color 0.45s ease-in-out;
$input-border: 1px solid color("input", "border");
$input-background-focus: color("input", "background-focus");
$input-border-focus: color("input", "border-focus");
$input-placeholder-color: color("input", "placeholder");

$button-sizes: (
  tiny: 0.75 nrem,
  small: 0.9rem,
  default: 1.11rem,
  large: 1.25rem,
);

$button-radius: 2px;

$anchor-color: color("link");
$anchor-text-decoration: underline;
$anchor-text-decoration-hover: none;

//$breakpoint-classes: (small medium large xlarge);
$titlebar-background: color("mainMenu", "background");
$offcanvas-background: color("mainMenu", "background");
$accordionmenu-arrow-color: color("mainMenu", "itemArrow");

$breadcrumbs-item-color: color("text");
$breadcrumbs-item-color-current: color("text");
$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-separator-color: color("text");

$callout-border: 1px solid #0a0a0a0d;

//$tab-background-active: color('accent');
//$tab-active-color: color('page');

//$label-padding: .5rem;

$table-background: $white;
$table-color-scale: 5%;
$table-head-background: smart-scale(
  $table-background,
  $table-color-scale * 1.5
);

/// Padding inside of pagination items.
/// @type Number
$pagination-item-padding: rem-calc(5 10);
