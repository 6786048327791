//@import url(https://weloveiconfonts.com/api/?family=entypo);
//@import url(https://weloveiconfonts.com/api/?family=fontawesome);
@import url("https://fonts.googleapis.com/css?family=Varela+Round");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");

@import "fonts/_custom-icon-pack.scss";

//Font
$font-title: "Montserrat", sans-serif;
$font-text: "Montserrat", sans-serif;
//$fontawesome: "FontAwesome", sans-serif;
//$entypo: "entypo", sans-serif;
