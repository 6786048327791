.v-autocomplete {
  .v-autocomplete-list {
    background: color("page");
    border: 1px solid color("text");
    margin-top: 1px;
    text-align: left;
    width: 100%;
    z-index: 1;
  }

  .v-autocomplete-list-item {
    padding: 0.4em 1em;

    &.v-autocomplete-item-active {
      color: $dropdown-menu-item-color-active;
      background: $dropdown-menu-item-background-active;
    }
  }
}

.form-field.-autocomplete {
  ._input-wrapper {
    position: relative;
  }

  ._loading-spinner {
    position: absolute;
    right: 7px;
    top: 50%;
    margin-top: -8px;
  }
}
