%grid-page {
  @extend %content-page;
  @extend %content-panel;

  ._page-title {
    margin-top: 1em;
  }

  ._page-content {
    margin-top: 1.5em;

    .action-bar {
      text-align: right;
    }

    .items-grid {
      margin-top: 1em;

      ::v-deep tbody td {
        cursor: pointer;
      }

      ._actions-column {
        text-align: center;

        > * {
          margin-bottom: -0.25em;
          margin-top: -0.25em;
        }

        ._action {
          display: block;
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 0.25em;
          margin-top: 0.25em;
          width: auto;
        }
      }

      &.-loading {
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
      }
    }
  }

  @include breakpoint(medium) {
    ._page-content {
      > .items-grid {
        ._actions-column {
          ._action {
            display: inline-block;

            & + ._action {
              margin-left: 0.5em;
            }
          }
        }
      }
    }
  }
}
