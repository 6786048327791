.VueTables {
  thead {
    th {
      text-align: center;

      &.VueTables__sortable {
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }
    }

    input,
    select {
      margin-bottom: 0;
    }
  }

  ._sorting-icon {
    display: inline-block;
    margin-left: 0.5em;
    vertical-align: middle;
    visibility: hidden;
  }

  .-sorted-asc {
    transform: rotate(270deg);
    visibility: visible;
  }

  .-sorted-desc {
    transform: rotate(90deg);
    visibility: visible;
  }

  &.-loading {
    opacity: 0.5;
  }

  tbody {
    td {
      &.-text {
        text-align: left;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    margin-top: 0;

    .VueTables__limit-field {
      display: none;

      select {
        cursor: pointer;
        margin-bottom: 0;
        width: auto;
      }
    }

    .VuePagination {
      flex-grow: 1;
      width: auto;

      > nav {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
      }

      .VuePagination__count {
        display: none;
        flex-grow: 1;
        width: auto;
      }

      .pagination-control {
        margin-bottom: 0;
        margin-left: 0.5em;
      }
    }
  }

  @include breakpoint(medium) {
    .pagination-controls {
      .VueTables__limit-field {
        display: block;
      }
    }
  }

  @include breakpoint(768) {
    .pagination-controls {
      .VuePagination {
        .VuePagination__count {
          display: block;
        }
      }
    }
  }
}
